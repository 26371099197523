<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Name -->
                  <b-col
                    lg="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="content.name"
                          maxlength="300"
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Referance ID -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('REFERENCE_ID')"
                      label-for="id-reference-number"
                    >
                      <b-form-input
                        id="id-reference-number"
                        v-model="content.externalReference"
                        maxlength="45"
                        :placeholder="$t('REFERENCE_ID')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Category -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('CATEGORY')"
                      label-for="id-category"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CATEGORY')"
                        rules="required"
                      >
                        <v-select
                          id="id-category"
                          v-model="content.tag"
                          :placeholder="$t('SELECT_CATEGORY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="allCategories"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Content Type -->
                  <b-col
                    v-if="content.id===0"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('CONTENT_TYPE')"
                      label-for="id-contentType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CONTENT_TYPE')"
                        rules="required"
                      >
                        <v-select
                          id="id-contentType"
                          v-model="contentType"
                          :placeholder="$t('CONTENT_TYPE')"
                          :clearable="false"
                          label="name"
                          class="flex-grow-1"
                          :options="allContentTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Content -->
                  <b-col
                    v-if="content.id===0"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('COPIED_FROM')"
                      label-for="id-content-source"
                    >
                      <v-select
                        id="id-content-source"
                        v-model="sourceContent"
                        :placeholder="$t('SELECT_CONTENT')"
                        label="name"
                        :clearable="false"
                        class="flex-grow-1"
                        :options="filteredContents"
                        :disabled="!(contentType.id > 0)"
                        @input="handleChangeContent($event)"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Status -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('STATUS')"
                      label-for="id-status"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('STATUS')"
                        rules="required"
                      >
                        <v-select
                          id="id-status"
                          v-model="contentStatus"
                          :placeholder="$t('SELECT_STATUS')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="allStatus"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Frequency -->
                  <b-col
                    v-if="content.id===0"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('FREQUENCY')"
                      label-for="id-frequency"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('FREQUENCY')"
                        rules="required"
                      >
                        <v-select
                          id="id-frequency"
                          v-model="frequency"
                          :placeholder="$t('SELECT_FREQUENCY')"
                          :clearable="false"
                          label="name"
                          class="flex-grow-1"
                          :options="allFrequencies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Company -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="company"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="allCompanies"
                          @input="handleChangeCompany($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Project -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('Project')"
                      label-for="id-project"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Project')"
                        rules="required"
                      >
                        <v-select
                          id="id-project"
                          v-model="project"
                          :placeholder="$t('SELECT_PROJECT')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="allProjects"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Strat date -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('START_DATE')"
                      label-for="id-strat-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('START_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-strat-date"
                          v-model="content.start"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- End date -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('END_DATE')"
                      label-for="id-end-date"
                    >
                      <flat-pickr
                        id="id-end-date"
                        v-model="content.end"
                        class="form-control"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- Lenght -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('DURATION')"
                      label-for="id-length"
                    >
                      <b-form-input
                        id="id-length"
                        v-model="content.length"
                        type="number"
                        maxlength="3"
                        :placeholder="$t('DURATION')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Language -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >
                        <v-select
                          id="id-language"
                          v-model="language"
                          :options="allLanguage"
                          label="name"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Analyze Individual -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-is_analyze_individual"
                        v-model="content.analyzeIndividual"
                        name="checkbox-is_analyze_individual"
                        class="mt-2"
                      >
                        {{ $t('AI_INDIVIDUAL_FEEDBACK') }}
                      </b-form-checkbox>
                    </b-form-group>

                  </b-col>
                  <!-- Template -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-template"
                        v-model="content.template"
                        name="checkbox-template"
                        class="mt-2"
                      >
                        {{ $t('CONTENT_MARK_AS_TEMPLATE') }}
                      </b-form-checkbox>
                    </b-form-group>

                  </b-col>
                  <!-- Chat Enabled -->
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-chat-enabled"
                        v-model="content.chatEnabled"
                        name="checkbox-chat-enabled"
                      >
                        {{ $t('CHAT_ENABLED') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="content.description"
                        maxlength="3000"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- Submit and Reset -->
                  <b-col cols="12">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ content.id ? $t("BUTTON.UPDATE") : $t("BUTTON.ADD") }}
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t("BUTTON.RESET") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-cycle
import { formatDate, avatarText } from '@core/utils/filter'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import { mixinList } from '@/constants/mixinValidations'
import { mapUserRoleToVariant } from '@/constants/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [mixinList],
  data() {
    return {
      content: {
        id: 0,
        externalReference: '',
        sourceContentId: null,
        contentStatusId: 0,
        contentFrequencyId: 0,
        contentTypeId: 0,
        projectId: 0,
        companyId: 0,
        length: 0,
        name: '',
        start: '',
        end: null,
        tag: '',
        language: '',
        chatEnabled: false,
        visibility: false,
        template: false,
        analyzeIndividual: false,
        description: constants.CONTENT_DESCRIPTION,
      },
      defaultDescription: constants.CONTENT_DESCRIPTION,
      sourceContent: '',
      project: '',
      company: '',
      language: '',
      category: '',
      contentStatus: '',
      contentType: '',
      frequency: '',
      allCategories: constants.CONTENT_TAG,
      allLanguage: constants.LANGUAGES,
      allCompanies: [],
      allProjects: [],
      allContents: [],
      allFrequencies: [],
      allStatus: [],
      allContentTypes: [],
      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'contentType.id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.sourceContent = null
      }
    },
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your content description',
    }
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
      editorOption,
      formatDate,
      mapUserRoleToVariant,
      avatarText,
    }
  },
  async created() {
    try {
      // eslint-disable-next-line radix
      const contentId = router.currentRoute.params.id ? parseInt(router.currentRoute.params.id) : 0
      // Fetch content types, frequencies, and status in parallel
      await Promise.all([
        this.fetchContentTypes(),
        this.fetchContentFrequencies(),
        this.fetchContentStatus(),
      ])

      // eslint-disable-next-line no-restricted-globals
      if (contentId && !isNaN(contentId)) {
        // Fetch the content by ID
        this.content.id = contentId
        const response = await this.fetchContentByID(this.content.id)

        if (response) {
          this.content = response.data

          // Fetch related data (projects, companies) in parallel
          await Promise.all([
            this.fetchProjects(this.content.companyId),
            this.fetchCompanies(),
          ])

          // Set values after fetching
          this.setProject()
          this.setContentType()
          this.setFrequencies()
          this.setLanguage()
          this.setContentStatus()
        }
      } else {
        // If no content ID, fetch companies and contents
        await Promise.all([
          this.fetchCompanies(),
          this.fetchContents(),
        ])
      }
    } catch (error) {
      this.errorMessage(this.$i18n.t('MESSAGE.PAGE_LOAD_FAILED'))
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
    ...mapGetters('project', ['getAllProjects']),
    filteredContents() {
      return this.allContents.filter(content => content.template === true && content.contentTypeId === this.contentType.id)
    },
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('project', ['fetchProjectByCompanyID']),
    ...mapActions('content', [
      'createContent',
      'updateContent',
      'fetchContentByID',
      'fetchAllContents',
      'fetchAllContentTypes',
      'fetchAllContentFrequencies',
      'fetchAllContentStatuses',
    ]),
    checkContentFrequency(length, frequency, contectTypeId, startDate, endDate) {
      const contentStartDate = new Date(startDate)
      const contentEndDate = endDate ? new Date(endDate) : null

      if (contectTypeId === 2) {
        return true
      }

      if (frequency === constants.CONTENT_FREQUENCY[0].name && !contentEndDate) {
        this.warningMessage(`The end date cannot be empty for the selected frequency: ${frequency}. Please provide a valid end date to match the content frequency requirements`)
        return false
      }

      if (frequency !== constants.CONTENT_FREQUENCY[0].name) {
        const frequencyObj = constants.CONTENT_FREQUENCY.find(f => f.name === frequency)
        if (!frequencyObj) {
          this.warningMessage(`Frequency '${frequency}' is not valid.`)
          return false
        }

        if (contentEndDate) {
          const diffTime = Math.abs(contentEndDate - contentStartDate)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
          if (diffDays < frequencyObj.days) {
            this.warningMessage(`For ${frequency} frequency, start date and end date must be more than ${frequencyObj.days} days apart`)
            return false
          }
        }

        if (length > frequencyObj.days || length === 0) {
          if (length === 0) {
            this.warningMessage('The duration of the content cannot be zero. Please provide a valid duration')
          } else {
            this.warningMessage(`For a '${frequency}' frequency, the duration should be ${frequencyObj.days} days, but currently, the duration is set to ${length} days`)
          }
          return false
        }
        return true
      }
      return true
    },
    handleChangeContent() {
      if (this.sourceContent.description) {
        this.content.description = this.sourceContent.description
      } else {
        this.content.description = this.defaultDescription
      }
    },
    handleChangeCompany(e) {
      this.allProjects = []
      this.project = ''
      this.fetchProjects(e.id)
    },
    setLanguage() {
      if (this.content) {
        this.language = this.allLanguage.find(
          i => i.id === this.content.language,
        )
      }
    },
    setProject() {
      if (this.content.id) {
        this.project = this.allProjects.find(
          i => i.id === this.content.projectId,
        )
      }
    },
    setContentType() {
      if (this.content.id) {
        this.contentType = this.allContentTypes.find(
          i => i.id === this.content.contentTypeId,
        )
      }
    },
    setFrequencies() {
      if (this.content.id) {
        this.frequency = this.allFrequencies.find(
          i => i.id === this.content.contentFrequencyId,
        )
      }
    },
    setContentStatus() {
      if (this.content.id) {
        this.contentStatus = this.allStatus.find(
          i => i.id === this.content.contentStatusId,
        )
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchProjects(id) {
      try {
        const response = await this.fetchProjectByCompanyID(id)
        if (response) {
          this.allProjects = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchContents() {
      try {
        const response = await this.fetchAllContents()
        if (response) {
          this.allContents = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchContentFrequencies() {
      try {
        const response = await this.fetchAllContentFrequencies()
        if (response) {
          this.allFrequencies = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchContentTypes() {
      try {
        const response = await this.fetchAllContentTypes()
        if (response) {
          this.allContentTypes = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchContentStatus() {
      try {
        const response = await this.fetchAllContentStatuses()
        if (response) {
          this.allStatus = response.data
        }
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchCompanies() {
      try {
        const response = await this.fetchAllCompanies()
        if (response) {
          this.allCompanies = response.data
          if (this.content.id) {
            this.company = this.allCompanies.find(i => i.id === this.content.companyId)
          } else if (this.allCompanies.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.company = this.allCompanies[0]
            this.handleChangeCompany(this.company)
          }
        }
      } catch (error) {
        return error
      }
    },
    clearReferenceID() {
      if (this.content) {
        if (typeof this.content.externalReference === 'string' && this.content.externalReference === '') {
          this.content.externalReference = null
        }
      }
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.content.end = this.content.end === '' ? null : this.content.end
          this.content.contentTypeId = this.contentType.id
          this.content.contentStatusId = this.contentStatus.id
          this.content.contentFrequencyId = this.frequency.id
          const frequencyCheck = this.checkContentFrequency(this.content.length, this.frequency.name, this.content.contentTypeId, this.content.start, this.content.end)
          if (frequencyCheck) {
            this.clearReferenceID()
            this.content.companyId = this.company.id
            this.content.projectId = this.project && this.project.id !== null ? this.project.id
              : 0
            this.content.sourceContentId = this.sourceContent && this.sourceContent.id !== null
              ? this.sourceContent.id
              : null
            this.content.language = this.language.id
            // this.content.is_private = this.scope.id
            if (this.content.id === 0) {
              this.createContent(this.content)
                .then(response => {
                  if (response) {
                    this.successMessage(this.$i18n.t('MESSAGE.CONTENT_CREATED'))
                    this.$router.push({ name: 'content-list' })
                  }
                }).catch(error => {
                  if (error.response.data.status === 'failed') {
                    this.errorMessage(error.response.data.message.toString())
                  }
                })
            } else {
              this.updateContent(this.content)
                .then(response => {
                  if (response) {
                    this.successMessage(this.$i18n.t('MESSAGE.CONTENT_UPDATED'))
                    this.$router.push({ name: 'content-list' })
                  }
                }).catch(error => {
                  if (error.response.data.status === 'failed') {
                    this.errorMessage(error.response.data.message.toString())
                  }
                })
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
